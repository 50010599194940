import React from 'react'
import '../../Style.css';
import '../../Media-styles.css';

import '../Styles/ProGrid.css'

import Slider from './Slider';

import ProjectFeatures from '../../../components/Project-Series/Project4/Features';


function ProjectFour() {
    return (
        <>



            <div className='inner_container'>


                <div className='pro-inner-body-container'>
                    <div className='inner_body'>
                        <section className="section-container">

                            <div className="section-body">
                                <div className="section-left">
                                    
                                    <Slider />


                                </div>

                                <div className="section-right-wht">
                                    
                                    <h2 className="product-feature">
                                    Wool and Thimble, WA
                                    </h2>

                                    <ProjectFeatures />

                                </div>
                            </div>
                           
                        </section>

                    </div>

                </div>
            </div>


        </>
    )
}

export default ProjectFour;
