import React from 'react'
import Header from './Header';
import Footer from './Footer';
import './Home.css';
import './Media-styles.css';


import Welcome from './Welcome';
import HomeBanner from './Home-Banner';
import { Helmet } from 'react-helmet';
function Home() {
  return (
    <>

<Helmet>
                <title>Acoustics</title>
                <meta name="title" content="Content here" />
                <meta name="description" content="Content here" />
                <meta name="keywords" content="Content here" />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
            </Helmet>
      <Header />

      <div className='home-container'>
        <HomeBanner />
        <div className='Home-section1'></div>
        <div className='Home-section1-mob'></div>
        <Welcome />
      
     
        
      </div>
      <Footer />
    </>
  )
}

export default Home
