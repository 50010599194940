import React from 'react'



function ProjectTwo() {
    return (
        <>

            <span>
                

                <div>
                  

                                        <table className="product-table">
                                          
                                            <tr>
                                                <td className="model-name">Client: <em class="emph">HBF Arena - Joondalup</em></td>
                                                
                                            </tr>
                                           
                                          
                                            <tr>
                                                <td className="model-name">Product: <em class="emph">Paneline</em></td>
                                                
                                            </tr>
                                          
                                            
                                           
                                            
                                            <tr>
                                                <td className="model-name">Status: <em class="emph">Completed</em></td>
                                                
                                            </tr>
                    
                                        </table>
                    
                    
                </div>

            </span>
        </>
    )
}

export default ProjectTwo

