import React from 'react'
import Paneline from './img/Projects/UWA/004.webp';
import Resilient from './img/Images/007.webp';

import AbtMainBanner from './img/Images/about-banner.webp';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';




function Product() {
  return (
    <>
    <Helmet>
    <title>Acoustics</title>
                <meta name="title" content="Content here" />
                <meta name="description" content="Content here" />
                <meta name="keywords" content="Content here" />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
            </Helmet>
      <Header />

 <div className='innerBanner'><img src={AbtMainBanner} alt="Acoustic" /></div>
<div className='welcome-container'>
       


        <div className='acu-col-right-abt'><div className='acu-copy-abt'><h1><em className='emph'>Our</em> Products</h1>

          <h3>Elevate your project to new heights with our exceptional range of acoustic products, seamlessly blending aesthetics with unparalleled acoustic qualities.</h3></div>
          </div>


      </div>

      <div className='welcome-container-abt'>
        <div className='acu-col-left'><img src={Paneline} alt="Acoustics"/></div>


        <div className='acu-col-right-abt2'><div className='acu-copy'><h1>Paneline</h1>

          <h3>Easy to install and detail, our superior grade acoustic ceiling and suspended ceiling system.</h3></div>
          <div className='pro-btn'><a href="./Paneline/">Read more</a></div>
          </div>


      </div>
      <div className='welcome-container2'>
      <div className='acu-col-left'><img src={Resilient} alt="Acoustics"/></div>


      <div className='acu-col-right-abt2'><div className='acu-copy'><h1>Resilient Mounts</h1>

        <h3>Our core range of resilient mount systems, these mount systems have wall and ceiling capacities that can enhance your acoustic requirements for your next project.</h3></div>
        <div className='pro-btn'><a href="./">Read more</a></div>
        </div>


    </div>


 
 
    


<Footer />
 
    </>
  )
}

export default Product
