import React from 'react'
import DiscoverBanner from './img/Images/discover.webp'
import ExcellBan from './img/Projects/Perth Airport/008.webp';

import AbtMainBanner from './img/Images/about-banner.webp';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import PromiseBanner from './img/Images/005.webp';


function About() {
  return (
    <>
    <Helmet>
    <title>Acoustics</title>
                <meta name="title" content="Content here" />
                <meta name="description" content="Content here" />
                <meta name="keywords" content="Content here" />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
            </Helmet>
      <Header/>

 <div><img src={AbtMainBanner} alt="Acoustic" /></div>
 


<div className='welcome-container'>
       


        <div className='acu-col-right-abt'><div className='acu-copy-abt'><h1><em className='emph'>Our</em> company</h1>

          <h3>Acoustics has been proudly manufacturing locally within WA, crafting a diverse range of acoustic ceiling supplies. Every product we provide, from our popular Paneline range to our Panelok ceiling framing systems and resilient mounts, reflects our dedication to quality and detail. At Acoustics, we enjoy taking on problems head-on and working with our clients to identify the solutions. We consistently push limits and surpass expectations because we are driven by our passion for innovation.</h3></div>
          </div>


      </div>

      <div className='welcome-container-abt'>
        <div className='acu-col-left'><img src={ExcellBan} alt="Acoustics"/></div>


        <div className='acu-col-right-abt2'><div className='acu-copy'><h1><em className='emph'>Our</em> Legacy</h1>

          <h3>Since 1983, Acoustics has been a part of the construction industry, earning a prominent reputation locally and nationally. Our legacy is defined by pioneering acoustic ceilings and accessories such as suspended ceiling framing and resilient mount systems. With our vast experience and expertise, we uphold an unwavering commitment to quality and service to all our clients and projects.</h3></div>
          </div>


      </div>

      <div className='welcome-container2'>
        <div className='welcome-col-left'><div className='welcome-copy'><h1><em className='emph'>Our</em> Focus </h1>

          <h3>At Acoustics, we embrace projects of all sizes, believing that none are too big or too small for our expertise. With over 35 years of experience in the construction industry, we've contributed to over 900 projects. From small commercial builds to regional schools, we uphold our core values of honesty, professionalism, top-quality craftsmanship and collaboration in every endeavor.</h3></div>
        </div>



        <div className='welcome-col-right'><img src={DiscoverBanner} alt="welcome" /></div>


      </div>

      <div className='welcome-container-abt'>
        <div className='acu-col-left'><img src={ExcellBan} alt="Acoustics"/></div>


        <div className='acu-col-right-abt2'><div className='acu-copy'><h1><em className='emph'>Our</em> Promise</h1>

          <h3>Our promise is that each project receives the utmost care and attention to deliver excellence tailored to your needs.</h3></div>
          </div>


      </div>

      <div className='welcome-container'>
       


        <div className='acu-col-right-abt'><div className='acu-copy-abt'><h1><em className='emph'>Our</em> Promise</h1>

          <h3>Our promise is that each project receives the utmost care and attention to deliver excellence tailored to your needs.</h3></div>
          </div>

         
      </div>
      <div><img src={PromiseBanner} alt="Acoustic" /></div>
       

    

    

      



<Footer />
 
    </>
  )
}

export default About
