import React from 'react'

import ResourcesBan from './img/Projects/Perth Airport/008.webp';


import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';




function Resources() {
  return (
    <>
    <Helmet>
    <title>Acoustics</title>
                <meta name="title" content="Content here" />
                <meta name="description" content="Content here" />
                <meta name="keywords" content="Content here" />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
            </Helmet>
      <Header />


<div className='resources-container'>
       


        <div className='acu-col-top-res'><div className='acu-copy-abt'><h1><em className='emph'>Paneline</em> Resources</h1>

          </div>
          </div>


      </div>

      <div className='resources-container'>
        <div className='acu-col-left2-res'><img src={ResourcesBan} alt="Acoustics"/></div>


        <div className='acu-col-right-abt2'><ul>
        <li className='resources'><a href="/demo/">Brochure PDF</a></li>
        <li className='resources'><a href="/demo/">Installation Guide PDF</a></li>
        <li className='resources'><a href="/demo/">Data Sheet PDF</a></li>
        <li className='resources-testing'><a href="/demo/">Acoustic Testing</a></li>
        <li className='resources-fire'><a href="/demo/">Fire Certificate</a></li>
        <li className='resources-warranty'><a href="/demo/">Warranty</a></li></ul>
          </div>


      </div>

  

   
     
       

   

    

      



<Footer />
 
    </>
  )
}

export default Resources
