import React from 'react'
import './Header.css';
import './Media-styles.css';
import Navigation from './Navigation';
import Logo from './img/Logo/Acoustics_Logo-25.webp'


function Header() {
  return (

    <div id='header-container'>
      <div className='header-logo-navbar'>
        <div id='acq-logo' className='header-left'><a href="/"><img src={Logo} alt="COpy here" /></a></div>

        <div className='header-right'><Navigation /></div>
      </div>
    </div>
  )
}

export default Header


