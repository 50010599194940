import React from 'react'

import ContactBan from './img/Images/flag.webp';


import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';



function Contact() {
  return (
    <>
    <Helmet>
    <title>Acoustics</title>
                <meta name="title" content="Content here" />
                <meta name="description" content="Content here" />
                <meta name="keywords" content="Content here" />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
            </Helmet>
      <Header />

   

<div className='welcome-container-contact'>
       


        <div className='acu-col-top-contact'><div className='acu-copy-abt'><h1>Contact us</h1>

          <h3>For technical advice, project specific queries or sales enquiries message us, and we'll respond promptly. Or, call us at 08 6156 6750. We're here to help!</h3></div>
          </div>


      </div>

      <div className='welcome-container-contact'>
        <div className='acu-col-contact'><img src={ContactBan} alt="Acoustics"/></div>


        <div className='acu-col-right-abt2'><iframe className='cframe' src="https://www.acoustic.com.au/connect/connect/"  title="contact" id='my-iframe' marginwidth='0' marginheight='0' frameborder='0' scrolling='no'></iframe>
          </div>


      </div>

  

   
     
       

   

    

      



<Footer />
 
    </>
  )
}

export default Contact
