import { Component } from 'react';
import './Navigation.css';
import './Media-styles.css';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { NavLink } from "react-router-dom";


class Navigation extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked })

  }

  render() {
    return (
  

    


      <div>
        <ul id="navbar" className={this.state.clicked ? "#navbar active" : "#navbar"}>

       
        <li className='desk-menu'><NavLink to="/Paneline">Paneline</NavLink></li>

        <li className='desk-menu'><NavLink to="/ProRange">Portfolio</NavLink></li>
        <li className='desk-menu navbar-dropdown'><a href="#">Downloads</a>

        <div class="dropdown">
            <h3>Our Documents</h3>
            <a href="https://www.acoustic.com.au/downloads/Acoustics-Product-Brochure.pdf" target="_blank" rel="noreferrer">Product Brochure</a>
            <a href="http://acoustic.com.au/downloads/Acoustics-Installation-Guide.pdf" target="_blank" rel="noreferrer">Installation Guide</a>
            <a href="http://acoustic.com.au/downloads/Acoustics-Data-Sheet.pdf" target="_blank" rel="noreferrer">Data Sheet</a>
            <a href="http://acoustic.com.au/downloads/Tests/Acoustics-Test-Certificate.pdf" target="_blank" rel="noreferrer">Acoustic Test Certificate</a>
            <a href="http://acoustic.com.au/downloads/Tests/Acoustics-Fire-Test-Certificate.pdf" target="_blank" rel="noreferrer">Fire Test Certificate</a>
            <a href="http://acoustic.com.au/downloads/Warranty/Acoustics-Warranty-Policy.pdf" target="_blank" rel="noreferrer">Warranty Policy</a>
    
        </div></li>
        <li className='desk-menu'><NavLink to="/Contact-us">Contact Us</NavLink></li>

          <li className='desk-menu phone'><a href="tel:0861566750">08 6156 6750</a></li>
          


        </ul>
        <div id="mobile" onClick={this.handleClick}>

          <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
          <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
          <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
      </div>
    )
  }
}



export default Navigation