import React from 'react'
import AcBanner from './img/Images/banner_25-jan.webp'
import AcBannerM from './img/Images/banner_m_25.webp'

//import MainBanner from './Home-Main-Banners';

function HomeBanner() {
  return (
    <>
      <div className='main-banner'><a href="/About/"><img src={AcBanner} alt="Acoustic" /></a>

      
				</div>
      <div className='main-banner-mob'><img src={AcBannerM} alt="Acoustic" /></div>


      
    </>
  )
}

export default HomeBanner
