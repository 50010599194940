import React from 'react'


import './Parallax.css';

import Project1 from '../Models/Project1/Pro-one';
import Project2 from '../Models/Project2/Pro-two';
import Project3 from '../Models/Project3/Pro-three';
import Project4 from '../Models/Project4/Pro-four';






function AllProjects() {
  return (
    <>
  
   

      <div className='welcome-container2'>
        <Project1 />
      </div>

      <div className='welcome-container-abt'>
      <Project2 />
      </div>

      <div className='welcome-container2'>
      <Project3 />
    </div>

    <div className='welcome-container-abt'>
    <Project4 />
    </div>

      
    
</>

 
  )
}

export default AllProjects



