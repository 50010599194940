
import './App.css';
import './components/Font_style.css';

import './components/Media-styles.css';

import Home from './components/Home';
import About from './components/About';
import ProRange from './components/Projects';
import Products from './components/Products';

import Paneline from './components/Paneline';
import Contact from './components/Contact-us';
import PaneResources from './components/Paneline-resources';

import Project1 from './components/Models/Project1/Pro-one';
import Project2 from './components/Models/Project2/Pro-two';
import Project3 from './components/Models/Project3/Pro-three';
import Project4 from './components/Models/Project4/Pro-four';






import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },

  {
    path: "/About",
    element: <About />,
  },

  {
    path: "/ProRange",
    element: <ProRange />,
  },

  {
    path: "/Products",
    element: <Products />,
  },

  {
    path: "/Paneline",
    element: <Paneline />,
  },

  {
    path: "/Contact-us",
    element: <Contact />,
  },

  {
    path: "/Paneline-resources",
    element: <PaneResources />,
  },



  {
    path: "/Project1",
    element: <Project1 />,
  },
  {
    path: "/Project2",
    element: <Project2 />,
  },
  {
    path: "/Project3",
    element: <Project3 />,
  },
  {
    path: "/Project4",
    element: <Project4 />,
  },
  

  




]);





function App() {
  return (
    <div className="App">

      <RouterProvider router={router} />

    </div>
  );
}

export default App;
