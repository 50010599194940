import React from 'react'
import './Style.css';
import './Media-styles.css';

import Header from './Header';
import Footer from './Footer';



import Projects from './Projects/OurProjects'
import { Helmet } from 'react-helmet';
function ProjectDesign() {


    return (

        

        <>
         <Helmet>
         <title>Acoustics</title>
                <meta name="title" content="Content here" />
                <meta name="description" content="Content here" />
                <meta name="keywords" content="Content here" />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
            </Helmet>
            <Header />

            <div className='inner_container blank-space'>
               
                <div className='inner-body-container'>
                    <div className='inner_body'><div className='main-body'><h1>Our Projects</h1>
                        
                        </div>
                        <Projects />
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )


}

export default ProjectDesign
